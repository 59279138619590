import { __awaiter } from "tslib";
// TODO: This is a temporary entrypoint used for desktop only and will be removed. It is needed so that bp dealCards used on mobile web tests (MobileWeb Unified Feed Test V2.1) don't override different version bp dealCard used on desktop.
import { useAdsCore } from '@slickdeals/ads-core';
import readyState from 'ready-state';
import LazyLoad from '@slickdeals/blueprint-twig/javascript/core/lazyLoad';
import '@slickdeals/blueprint-twig/javascript/patterns/dealContentListModule';
import '@slickdeals/blueprint-twig/javascript/patterns/storeLink';
import '@slickdeals/blueprint-twig/javascript/patterns/storeCard';
import '@slickdeals/blueprint-twig/javascript/components/cardCarousel';
import '../patterns/latestArticles';
import './standalonePageEntrypointBanner';
import '../patterns/trendingStores';
import '../patterns/sidebarDeals';
import '../patterns/sidebarCoupons';
import '../recipes/holidaySeoBlock';
import '../recipes/cashbackOffersWidget';
import '../patterns/cashbackTagActivationModal';
import '../patterns/inlineResponse';
import { ClassNames as JustForYouCarouselWrapperClassNames } from '../patterns/justForYouCarouselWrapper';
import { initializeDealCardSubscriber } from '../subscribers/dealCardSubscriber';
import AdRefreshEvents from '../utilities/adRefreshEvents';
/** The name of the module */
export const name = 'Frontpage';
useAdsCore().initAdStack();
initializeDealCardSubscriber();
readyState.domready.then(() => __awaiter(void 0, void 0, void 0, function* () {
    const lazyLoad = new LazyLoad();
    (new AdRefreshEvents()).initializeAdRefreshOnUpscroll();
    const frontpageSlickdealsContainer = document.querySelector('[data-module-name*="Frontpage Slickdeals"]');
    const dealYouMayHaveMissedContainer = document.querySelector('[data-module-name="Deals You May Have Missed"]');
    const cashbackOffersWidgetContainer = document.querySelector('[data-module-name*="Best Cashback Offers"]');
    const justForYouCarouselContainer = document.querySelector(`.${JustForYouCarouselWrapperClassNames.Javascript}`);
    const lazyLoadContainers = [frontpageSlickdealsContainer, dealYouMayHaveMissedContainer, cashbackOffersWidgetContainer, justForYouCarouselContainer];
    lazyLoadContainers.forEach(container => {
        if (container !== null) {
            lazyLoad.setObserver(container);
        }
    });
}));
